.container {
  display: flex;
  justify-content: center;
  text-align: right; 
}

.calenderStyle {
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, .2);
}
